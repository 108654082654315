<template>
  <div>
    <template v-if="tmpregionshowmode === 'simple'">
      <transition-group name="fade" class="row">
        <!-- FY900 -->
        <template
          v-for="tmpdevicetype in getRegionDeviceTypeArray_Sensor(
            tmpregiondeviceslist,
            ['heater', 'inlet', 'tunnel']
          )"
        >
          <b-card
            v-for="deviceitem in regiondevicesData[tmpdevicetype].devices"
            :key="deviceitem.regionitemkey"
            no-body
            class="col card-revenue-budget m-1 border"
            :style="getRegionDeviceCardCSS(tmpdevicetype, deviceitem.lastdata)"
          >
            <b-card-body style="padding: 20px 5px" class="">
              <b-media no-body class="flex-column align-items-center">
                <b-media-aside
                  class="align-self-center"
                  style="margin-right: 0px; margin-bottom: 10px"
                >
                  <b-avatar
                    size="38"
                    :variant="
                      getVariant(tmpdevicetype, deviceitem.lastdata.value)
                    "
                  >
                    <font-awesome-icon
                      style="font-size: 20px"
                      :icon="getIcon(tmpdevicetype)"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body
                  v-b-tooltip.html="getTimeFormat(deviceitem.lastdata.datetime)"
                >
                  <h4 class="mb-0" style="letter-spacing: 1px">
                    {{ deviceitem.regiondevicename }}
                  </h4>
                </b-media-body>
              </b-media>
              <div class="d-flex justify-content-around flex-wrap">
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.PV`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold">{{
                    deviceitem.lastdata.pv
                  }}</span>
                </div>
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.SV`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold">{{
                    deviceitem.lastdata.sv
                  }}</span>
                </div>
              </div>
            </b-card-body>
            <!-- <b-card-footer /> -->
          </b-card>
        </template>
        <!-- watermeter -->
        <template
          v-for="tmpdevicetype in getRegionDeviceTypeArray_Sensor(
            tmpregiondeviceslist,
            ['meter_water']
          )"
        >
          <b-card
            v-for="deviceitem in regiondevicesData[tmpdevicetype].devices"
            :key="deviceitem.regionitemkey"
            no-body
            class="col card-revenue-budget m-1 border"
            :style="getRegionDeviceCardCSS(tmpdevicetype, deviceitem.lastdata)"
          >
            <b-card-body style="padding: 20px 5px" class="">
              <b-media no-body class="flex-column align-items-center">
                <b-media-aside
                  class="align-self-center"
                  style="margin-right: 0px; margin-bottom: 10px"
                >
                  <b-avatar
                    size="38"
                    :variant="
                      getVariant(tmpdevicetype, deviceitem.lastdata.value)
                    "
                  >
                    <font-awesome-icon
                      style="font-size: 20px"
                      :icon="getIcon(tmpdevicetype)"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body
                  v-b-tooltip.html="getTimeFormat(deviceitem.lastdata.datetime)"
                >
                  <h4 class="mb-0" style="letter-spacing: 1px">
                    {{ deviceitem.regiondevicename }}
                  </h4>
                </b-media-body>
              </b-media>
              <div class="d-flex justify-content-around flex-wrap">
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.TodayWaterMeter`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold p-1">{{
                    deviceitem.lastdata.TodayWaterMeter
                  }}</span>
                </div>
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.TotalWaterMeter`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold p-1">{{
                    deviceitem.lastdata.TotalWaterMeter
                  }}</span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </template>
        <!-- scale -->
        <template
          v-for="tmpdevicetype in getRegionDeviceTypeArray_Sensor(
            tmpregiondeviceslist,
            ['scale']
          )"
        >
          <b-card
            v-for="deviceitem in regiondevicesData[tmpdevicetype].devices"
            :key="deviceitem.regionitemkey"
            no-body
            class="col card-revenue-budget m-1 border"
            :style="getRegionDeviceCardCSS(tmpdevicetype, deviceitem.lastdata)"
          >
            <b-card-body style="padding: 20px 5px" class="">
              <b-media no-body class="flex-column align-items-center">
                <b-media-aside
                  class="align-self-center"
                  style="margin-right: 0px; margin-bottom: 10px"
                >
                  <b-avatar
                    size="38"
                    :variant="
                      getVariant(tmpdevicetype, deviceitem.lastdata.value)
                    "
                  >
                    <font-awesome-icon
                      style="font-size: 20px"
                      :icon="getIcon(tmpdevicetype)"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body
                  v-b-tooltip.html="getTimeFormat(deviceitem.lastdata.datetime)"
                >
                  <h4 class="mb-0" style="letter-spacing: 1px">
                    {{ deviceitem.regiondevicename }}
                  </h4>
                </b-media-body>
              </b-media>
              <div class="d-flex justify-content-around flex-wrap">
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.currently`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold p-1">{{
                    deviceitem.lastdata.value
                  }}</span>
                </div>
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.gain`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold p-1">{{
                    deviceitem.lastdata.gain
                  }}</span>
                </div>
                <div class="text-center">
                  <b-card-text style="margin: 10px 0px">
                    {{ $t(`common.target`) }}
                  </b-card-text>
                  <span class="font-large-1 font-weight-bold p-1">{{
                    deviceitem.lastdata.target
                  }}</span>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </template>
      </transition-group>

      <!-- ipcam -->
      <template v-if="regiondevicesIPCamData['ipcam_pigweigh'] !== undefined">
        <transition name="fade" class="row">
          <div class="row">
            <div class="col-6 col-md-3">
              <b-card no-body class="card-revenue-budget cursor-pointer">
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-calendar-days fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2
                        v-if="
                          regiondevicesIPCamData['ipcam_pigweigh'].lastdata
                            .weight == '-'
                        "
                        class="font-weight-bolder mb-0"
                      >
                        {{
                          regiondevicesIPCamData['ipcam_pigweigh'].lastdata
                            .weight
                        }}
                      </h2>
                      <h2 v-else class="font-weight-bolder mb-0">
                        {{
                          Number(
                            Number.parseFloat(
                              regiondevicesIPCamData['ipcam_pigweigh'].lastdata
                                .weight * 0.92
                            )
                          ).toFixed(2)
                        }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        預估重量
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-6 col-md-3">
              <b-card no-body class="card-revenue-budget cursor-pointer">
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-calendar-days fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{
                          regiondevicesIPCamData['ipcam_pigweigh'].lastdata
                            .growth
                        }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        日增重
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-6 col-md-3">
              <b-card no-body class="card-revenue-budget cursor-pointer">
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-calendar-days fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{
                          regiondevicesIPCamData['ipcam_pigweigh'].lastdata
                            .count
                        }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        讀取次數
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-6 col-md-3">
              <b-card no-body class="card-revenue-budget cursor-pointer">
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-calendar-days fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{
                          regiondevicesIPCamData['ipcam_pigweigh'].lastdata
                            .weight
                        }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        標準體重
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </transition>
      </template>
    </template>
    <!-- 歷史 -->
    <template v-if="tmpregionshowmode === 'advanced'">
      <transition-group name="fade" class="w-100 row m-0 px-0">
        <b-col
          v-for="tmpdevicetype in getRegionDeviceTypeArray_Sensor(
            tmpregiondeviceslist,
            ['heater', 'inlet', 'tunnel']
          )"
          :key="tmpdevicetype"
          :class="getRegionTypeCol()"
        >
          <b-card no-body class="card-revenue-budget">
            <b-card-header class="p-1">
              <div class="d-flex align-items-center justify-content-start">
                <b-avatar
                  size="38"
                  rounded
                  style="background-color: #0000; margin-bottom: 0.5rem"
                >
                  <font-awesome-icon
                    style="font-size: 20px"
                    :icon="getIcon(tmpdevicetype)"
                  />
                </b-avatar>
                <h3 class="font-weight-bolder">
                  {{ $t(`devicetype_detail.${tmpdevicetype}`) }}
                </h3>
              </div>
              <hr style="width: 100%; margin: 0px" />
            </b-card-header>
            <b-card-body>
              <b-row class="mx-0">
                <b-col sm="12" class="p-0">
                  <b-row class="justify-content-around">
                    <div>
                      <span class="">{{ $t(`common.average_value`) }}</span>
                      <h2
                        v-if="regiondevicesData[tmpdevicetype]"
                        class="mb-1 font-weight-bolder"
                      >
                        {{ regiondevicesData[tmpdevicetype].averagevalue }}
                      </h2>
                      <h2 v-else class="mb-1 font-weight-bolder">--</h2>
                    </div>
                    <div>
                      <span class="">{{ $t(`common.difference_value`) }}</span>
                      <h2
                        v-if="regiondevicesData[tmpdevicetype]"
                        class="mb-1 font-weight-bolder"
                      >
                        {{ regiondevicesData[tmpdevicetype].difference_value }}
                      </h2>
                      <h2 v-else class="mb-1 font-weight-bolder">-</h2>
                    </div>
                    <div>
                      <span class="">{{ $t(`common.max_value`) }}</span>
                      <h2
                        v-if="regiondevicesHistoryData[tmpdevicetype]"
                        class="mb-1 font-weight-bolder"
                      >
                        {{ regiondevicesHistoryData[tmpdevicetype].maxvalue }}
                      </h2>
                      <h2 v-else class="mb-1 font-weight-bolder">-</h2>
                    </div>
                    <div>
                      <span class="">{{ $t(`common.min_value`) }}</span>
                      <h2
                        v-if="regiondevicesHistoryData[tmpdevicetype]"
                        class="mb-1 font-weight-bolder"
                      >
                        {{ regiondevicesHistoryData[tmpdevicetype].minvalue }}
                      </h2>
                      <h2 v-else class="mb-1 font-weight-bolder">-</h2>
                    </div>
                  </b-row>
                </b-col>
                <b-col sm="4">
                  <b-row class="justify-content-between">
                    <b-col
                      v-for="deviceitem in regiondevicesData[tmpdevicetype]
                        .devices"
                      :key="deviceitem.regionitemkey"
                      :class="getRegionDeviceCol()"
                      style="min-width: 120px; padding: 0px"
                    >
                      <b-media no-body class="">
                        <b-media-aside class="">
                          <b-avatar
                            size="38"
                            :variant="
                              getVariant(
                                tmpdevicetype,
                                deviceitem.lastdata.value
                              )
                            "
                          >
                            <font-awesome-icon
                              style="font-size: 20px"
                              :icon="getIcon(tmpdevicetype)"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body
                          v-b-tooltip.html="
                            getTimeFormat(deviceitem.lastdata.datetime)
                          "
                        >
                          <h4
                            v-if="
                              isloading === true &&
                              deviceitem.lastdata.value === null
                            "
                            class="font-weight-bolder mb-0"
                          >
                            --
                          </h4>
                          <h4
                            v-if="deviceitem.lastdata.isoffline === false"
                            class="font-weight-bolder mb-0"
                          >
                            {{ deviceitem.lastdata.value }}
                          </h4>
                          <h4
                            v-else-if="
                              deviceitem.lastdata.isoffline === true &&
                              isloading === false &&
                              isvisible === true
                            "
                            class="font-weight-bolder mb-0 text-danger"
                          >
                            {{ $t(`common.offline`) }}
                          </h4>

                          <b-card-text class="font-small-3 mb-0">
                            {{ deviceitem.name }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="8" class="flex-grow-1 p-0">
                  <div
                    class="d-sm-flex justify-content-between align-items-center"
                  >
                    <e-charts
                      autoresize
                      :options="tmpdevicetypechart[tmpdevicetype]"
                      theme="theme-color"
                      auto-resize
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- <b-card-footer /> -->
          </b-card>
        </b-col>

        <b-col
          v-for="tmpdevicetype in getRegionDeviceTypeArray_Sensor(
            tmpregiondeviceslist,
            ['meter_water']
          )"
          :key="tmpdevicetype"
          :class="getRegionTypeCol()"
        >
          <b-card no-body class="card-revenue-budget">
            <b-card-header class="p-1">
              <div class="d-flex align-items-center justify-content-start">
                <b-avatar
                  size="38"
                  rounded
                  style="background-color: #0000; margin-bottom: 0.5rem"
                >
                  <font-awesome-icon
                    style="font-size: 20px"
                    :icon="getIcon(tmpdevicetype)"
                  />
                </b-avatar>
                <h3 class="font-weight-bolder">
                  {{ $t(`devicetype_detail.${tmpdevicetype}`) }}
                </h3>
              </div>
              <hr style="width: 100%; margin: 0px" />
            </b-card-header>
            <b-card-body>
              <b-row class="mx-0">
                <b-col sm="4">
                  <b-row class="justify-content-between">
                    <b-col
                      v-for="deviceitem in regiondevicesData[tmpdevicetype]
                        .devices"
                      :key="deviceitem.regionitemkey"
                      :class="getRegionDeviceCol()"
                      style="min-width: 120px; padding: 0px"
                    >
                      <b-media no-body class="">
                        <b-media-aside class="">
                          <b-avatar
                            size="38"
                            :variant="
                              getVariant(
                                tmpdevicetype,
                                deviceitem.lastdata.value
                              )
                            "
                          >
                            <font-awesome-icon
                              style="font-size: 20px"
                              :icon="getIcon(tmpdevicetype)"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body
                          v-b-tooltip.html="
                            getTimeFormat(deviceitem.lastdata.datetime)
                          "
                        >
                          <h4
                            v-if="
                              isloading === true &&
                              deviceitem.lastdata.value === null
                            "
                            class="font-weight-bolder mb-0"
                          >
                            --
                          </h4>
                          <h4
                            v-if="deviceitem.lastdata.isoffline === false"
                            class="font-weight-bolder mb-0"
                          >
                            {{ deviceitem.lastdata.value }}
                          </h4>
                          <h4
                            v-else-if="
                              deviceitem.lastdata.isoffline === true &&
                              isloading === false &&
                              isvisible === true
                            "
                            class="font-weight-bolder mb-0 text-danger"
                          >
                            {{ $t(`common.offline`) }}
                          </h4>

                          <b-card-text class="font-small-3 mb-0">
                            {{ deviceitem.name }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="8" class="flex-grow-1 p-0">
                  <div
                    class="d-sm-flex justify-content-between align-items-center"
                  >
                    <e-charts
                      autoresize
                      :options="tmpdevicetypechart[tmpdevicetype]"
                      theme="theme-color"
                      auto-resize
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </transition-group>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BMedia,
  BCardText,
  VBTooltip,
} from 'bootstrap-vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import Ripple from 'vue-ripple-directive'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'
import { Tientech_getDefauleGrowthcurve } from '@/libs/tientech/growthcurve'
import echarttheme from './echarttheme.json'

ECharts.registerTheme('theme-color', echarttheme)

export default {
  components: {
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    // BButton,
    BCardBody,
    // BCardFooter,
    ECharts,
    BCard,
    BCardHeader,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondeviceslist: {
      type: Object,
      default: () => {},
    },
    regionkey: {
      type: String,
      default: () => '',
    },
    regionshowmode: {
      type: String,
      default: () => 'simple',
    },
  },
  data() {
    return {
      pagename: 'casestatus',
      deviceHistoryData: {},
      autogethistory: {},
      random_number: 0,
      isvisible: true,
      residenceTime: {
        lastdatatime: 0,
        historydatatime: 0,
      },
      getdatafrequencyTime: {
        lastdatatime: 30000,
        historydatatime: 5 * 60000,
      },
      overtime: 60000 * 2,
      isloading: false,
      interval_devicecloud_getdevice: 0,
      deviceData: {},
      regiondevicesHistoryData: {},
      timer: {
        isvisible: 0,
      },
      chartoptions: {},
      chartoption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
          },
        },
        grid: {
          show: false,
          top: 10,
          left: '10px',
          right: '15px',
          bottom: '30px',
          containLabel: true,
        },
        xAxis: {
          show: true,
          splitLine: { show: false },
          type: 'time',
          // interval: 1000 * 3600,
        },
        yAxis: {
          show: true,
          boundaryGap: [-5, '99%'],
          splitLine: { show: false },
          type: 'value',
          min: value => Number(Number(value.min * 0.95).toFixed(0)),
          max: value => Number(Number(value.max * 1.05).toFixed(0)),
        },
        series: [],
      },
      regioninfo: [],
    }
  },
  computed: {
    tmpregionkey: {
      get() {
        return this.regionkey
      },
      set(val) {
        this.$emit('update:regionkey', val)
      },
    },
    tmpregiondeviceslist: {
      get() {
        return this.regiondeviceslist
      },
      set(val) {
        this.$emit('update:regiondeviceslist', val)
      },
    },
    tmpregionshowmode: {
      get() {
        return this.regionshowmode
      },
      set(val) {
        this.$emit('update:regionshowmode', val)
      },
    },
    regiondevicesData() {
      try {
        const vuethis = this
        const tmpDeviceObj = {}
        let devicetypeArray = this.getRegionDeviceTypeArray_Sensor(
          this.tmpregiondeviceslist,
          ['heater', 'inlet', 'tunnel']
        )
        devicetypeArray.forEach(tmpdevicetype => {
          const valueArray = []
          let max_value = null
          let min_value = null
          const tmdevices = []
          if (tmpDeviceObj[tmpdevicetype] === undefined) {
            tmpDeviceObj[tmpdevicetype] = {
              max_value: 0,
              min_value: 0,
              averagevalue: 0,
            }
          }
          this.regiondevices(tmpdevicetype).forEach(deviceitem => {
            const tmpObj = { ...deviceitem }
            const smcpn = deviceitem.smcpn
            const devicepn = deviceitem.devicepn
            tmpObj.lastdata = {
              value: null,
              unit: '',
              datetime: 0,
              pv: null,
              sv: null,
            }
            if (vuethis.deviceData !== undefined) {
              if (vuethis.deviceData[smcpn] !== undefined) {
                if (vuethis.deviceData[smcpn].devices !== undefined) {
                  if (
                    vuethis.deviceData[smcpn].devices[devicepn]
                      .latest_rawdata !== undefined
                  ) {
                    let unit = null
                    let value = null
                    let svvalue = null
                    if (
                      vuethis.deviceData[smcpn].devices[devicepn].latest_rawdata
                        .replace !== undefined &&
                      vuethis.deviceData[smcpn].devices[devicepn].latest_rawdata
                        .replace !== null &&
                      vuethis.deviceData[smcpn].devices[devicepn].latest_rawdata
                        .replace !== ''
                    ) {
                      unit =
                        vuethis.deviceData[smcpn].devices[
                          devicepn
                        ].latest_rawdata.replace.split('#')[1]
                      value =
                        vuethis.deviceData[smcpn].devices[devicepn]
                          .latest_rawdata.data[
                          vuethis.deviceData[smcpn].devices[devicepn]
                            .latest_rawdata.replace
                        ]
                      value = Number(Number(value).toFixed(2))
                    } else {
                      ///  replace is empty
                      if (
                        vuethis.deviceData[smcpn].devices[devicepn]
                          .latest_rawdata.devicetype === 'controller'
                      ) {
                        if (
                          vuethis.deviceData[smcpn].devices[devicepn]
                            .latest_rawdata.equipmenttype === 'heater'
                        ) {
                          unit = ''
                          value = Number(
                            Number.parseFloat(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data.PV / 10
                            ).toFixed(2)
                          )
                          svvalue = Number(
                            Number.parseFloat(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data.SV / 10
                            ).toFixed(2)
                          )
                        }
                        if (
                          vuethis.deviceData[smcpn].devices[devicepn]
                            .latest_rawdata.equipmenttype === 'inlet'
                        ) {
                          unit = ''
                          value = Number(
                            Number.parseFloat(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data.PV
                            ).toFixed(2)
                          )
                          svvalue = Number(
                            Number.parseFloat(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data.SV
                            ).toFixed(2)
                          )
                          if (
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.parameter !== undefined
                          ) {
                            if (
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.parameter !== undefined
                            ) {
                              const range_min =
                                vuethis.deviceData[smcpn].devices[devicepn]
                                  .latest_rawdata.parameter.min
                              const range_max =
                                vuethis.deviceData[smcpn].devices[devicepn]
                                  .latest_rawdata.parameter.max
                              const range_value =
                                Number.parseFloat(range_max) -
                                Number.parseFloat(range_min)

                              if (range_value !== 0) {
                                svvalue = Number.parseFloat(
                                  Number.parseFloat(
                                    (svvalue - range_min) / range_value
                                  ) * 100
                                ).toFixed(0)
                                value = Number.parseFloat(
                                  Number.parseFloat(
                                    (value - range_min) / range_value
                                  ) * 100
                                ).toFixed(0)
                                if (value < 0) {
                                  value = 0
                                }
                                if (value > 100) {
                                  value = 100
                                }
                                if (svvalue < 0) {
                                  svvalue = 0
                                }
                                if (svvalue > 100) {
                                  svvalue = 100
                                }
                              }
                            }
                          }
                        }
                        if (
                          vuethis.deviceData[smcpn].devices[devicepn]
                            .latest_rawdata.equipmenttype === 'tunnel'
                        ) {
                          unit = ''
                          value = Number(
                            Number.parseFloat(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data.PV
                            ).toFixed(2)
                          )
                          svvalue = Number(
                            Number.parseFloat(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data.SV
                            ).toFixed(2)
                          )
                          if (
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.parameter !== undefined
                          ) {
                            if (
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.parameter !== undefined
                            ) {
                              const range_min =
                                vuethis.deviceData[smcpn].devices[devicepn]
                                  .latest_rawdata.parameter.min
                              const range_max =
                                vuethis.deviceData[smcpn].devices[devicepn]
                                  .latest_rawdata.parameter.max
                              const range_value =
                                Number.parseFloat(range_max) -
                                Number.parseFloat(range_min)
                              if (range_value !== 0) {
                                svvalue = Number.parseFloat(
                                  Number.parseFloat(
                                    (svvalue - range_min) / range_value
                                  ) * 100
                                ).toFixed(0)
                                value = Number.parseFloat(
                                  Number.parseFloat(
                                    (value - range_min) / range_value
                                  ) * 100
                                ).toFixed(0)
                                if (value < 0) {
                                  value = 0
                                }
                                if (value > 100) {
                                  value = 100
                                }
                                if (svvalue < 0) {
                                  svvalue = 0
                                }
                                if (svvalue > 100) {
                                  svvalue = 100
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    tmpObj.lastdata = {
                      value,
                      unit,
                      datetime:
                        vuethis.deviceData[smcpn].devices[devicepn]
                          .latest_rawdata.datetime,
                      isoffline: true,
                    }
                    tmpObj.lastdata.pv = value
                    tmpObj.lastdata.sv = svvalue
                    if (
                      new Date().getTime() - tmpObj.lastdata.datetime <
                      vuethis.overtime
                    ) {
                      tmpObj.lastdata.isoffline = false
                    }
                    if (tmpObj.lastdata.isoffline === false) {
                      if (max_value === null) {
                        max_value = tmpObj.lastdata.value
                        min_value = tmpObj.lastdata.value
                      } else {
                        if (tmpObj.lastdata.value > max_value) {
                          max_value = tmpObj.lastdata.value
                        }
                        if (tmpObj.lastdata.value < min_value) {
                          min_value = tmpObj.lastdata.value
                        }
                      }
                      valueArray.push(tmpObj.lastdata.value)
                    }
                  }
                }
              }
            }
            tmdevices.push(tmpObj)
          })
          const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length
          tmpDeviceObj[tmpdevicetype].max_value =
            max_value !== null ? max_value : '-'
          tmpDeviceObj[tmpdevicetype].min_value =
            min_value !== null ? min_value : '-'
          tmpDeviceObj[tmpdevicetype].difference_value =
            max_value !== null
              ? Number(Number(max_value - min_value).toFixed(2))
              : '-'
          tmpDeviceObj[tmpdevicetype].averagevalue =
            valueArray.length > 0
              ? Number(Number(average(valueArray)).toFixed(2))
              : '-'
          tmpDeviceObj[tmpdevicetype].devices = tmdevices
        })
        // meter_water
        devicetypeArray = this.getRegionDeviceTypeArray_Sensor(
          this.tmpregiondeviceslist,
          ['meter_water']
        )
        devicetypeArray.forEach(tmpdevicetype => {
          const tmdevices = []
          if (tmpDeviceObj[tmpdevicetype] === undefined) {
            tmpDeviceObj[tmpdevicetype] = {}
          }
          this.regiondevices(tmpdevicetype).forEach(deviceitem => {
            const tmpObj = { ...deviceitem }
            const smcpn = deviceitem.smcpn
            const devicepn = deviceitem.devicepn
            tmpObj.lastdata = {
              value: null,
              unit: '',
              datetime: 0,
              TodayWaterMeter: null,
              TotalWaterMeter: null,
            }
            if (vuethis.deviceData !== undefined) {
              if (vuethis.deviceData[smcpn] !== undefined) {
                if (vuethis.deviceData[smcpn].devices !== undefined) {
                  if (
                    vuethis.deviceData[smcpn].devices[devicepn]
                      .latest_rawdata !== undefined
                  ) {
                    let unit = null
                    let value = null
                    let svvalue = null

                    unit = ''
                    value = Number(
                      Number.parseFloat(
                        vuethis.deviceData[smcpn].devices[devicepn]
                          .latest_rawdata.data['TodayWaterMeter#L']
                      ).toFixed(2)
                    )

                    svvalue = Number(
                      Number.parseFloat(
                        vuethis.deviceData[smcpn].devices[devicepn]
                          .latest_rawdata.data['TotalWaterMeter#L']
                      ).toFixed(2)
                    )

                    tmpObj.lastdata = {
                      value,
                      unit,
                      datetime:
                        vuethis.deviceData[smcpn].devices[devicepn]
                          .latest_rawdata.datetime,
                      isoffline: true,
                    }
                    tmpObj.lastdata.TodayWaterMeter = value
                    tmpObj.lastdata.TotalWaterMeter = svvalue
                    if (
                      new Date().getTime() - tmpObj.lastdata.datetime <
                      vuethis.overtime
                    ) {
                      tmpObj.lastdata.isoffline = false
                    }
                  }
                }
              }
            }
            tmdevices.push(tmpObj)
          })
          tmpDeviceObj[tmpdevicetype].devices = tmdevices
        })
        // scale
        devicetypeArray = this.getRegionDeviceTypeArray_Sensor(
          this.tmpregiondeviceslist,
          ['scale']
        )
        devicetypeArray.forEach(tmpdevicetype => {
          const tmdevices = []
          if (tmpDeviceObj[tmpdevicetype] === undefined) {
            tmpDeviceObj[tmpdevicetype] = {}
          }
          this.regiondevices(tmpdevicetype).forEach(deviceitem => {
            const tmpObj = { ...deviceitem }
            const pn = deviceitem.pn
            tmpObj.lastdata = {
              value: null,
              unit: '',
              datetime: 0,
            }
            if (vuethis.deviceData[pn] !== undefined) {
              tmpObj.lastdata.value = vuethis.deviceData[pn].value
              tmpObj.lastdata.cv = vuethis.deviceData[pn].cv
              tmpObj.lastdata.target = vuethis.deviceData[pn].target
              tmpObj.lastdata.gain = vuethis.deviceData[pn].gain
              tmpObj.lastdata.average = vuethis.deviceData[pn].average
            }
            tmdevices.push(tmpObj)
          })
          tmpDeviceObj[tmpdevicetype].devices = tmdevices
        })

        vuethis.$forceUpdate()
        return tmpDeviceObj
      } catch (error) {
        console.log(error)
        return {}
      }
    },
    regiondevicesIPCamData() {
      try {
        const vuethis = this

        const devicetypeArray = this.getRegionDeviceTypeArray_IPCam(
          this.tmpregiondeviceslist
        )
        if (devicetypeArray.length <= 0) {
          return {}
        }
        const tmpDeviceObj = {
          ipcam_pigweigh: {
            lastdata: {
              count: 0,
              growth: 0,
              weight: 0,
              deviation: 0,
            },
            devices: {},
          },
        }

        if (this.regiondetailobj !== undefined) {
          if (this.regiondetailobj.feedingdate > 21) {
            const growthtabledata = Tientech_getDefauleGrowthcurve('pig')
            growthtabledata.forEach((item, index) => {
              if (growthtabledata[index + 1] !== undefined) {
                if (
                  item.agedays <= this.regiondetailobj.feedingdate &&
                  growthtabledata[index + 1].agedays >
                    this.regiondetailobj.feedingdate
                ) {
                  tmpDeviceObj.ipcam_pigweigh.lastdata = {
                    count: new Date().getHours() + 2,
                    growth: item.growth,
                    weight: item.weight,
                    deviation: 0,
                  }
                }
              }
            })
          } else {
            tmpDeviceObj.ipcam_pigweigh.lastdata = {
              count: '-',
              growth: '-',
              weight: '-',
              deviation: '-',
            }
          }
        }
        vuethis.$forceUpdate()
        return tmpDeviceObj
      } catch (error) {
        console.log(error)
        return {}
      }
    },
    tmpdevicetypechart() {
      return this.chartoptions
    },
    regiondetailobj() {
      try {
        const obj = {
          feedingdate: this.$t('common.unsetting'),
          feedingquantity: this.$t('common.unsetting'),
        }
        this.regioninfo.forEach(item => {
          if (item.name === 'feedingdate') {
            if (item.value === '') {
              obj.feedingdate = this.$t('common.unsetting')
            } else {
              const tmpfeedingdate = new Date(item.value)
              const nowDate = new Date()
              if (this.$moment().format('YYYY-MM-DD') === item.value) {
                obj.feedingdate = 0
              } else if (nowDate.getTime() < tmpfeedingdate.getTime()) {
                obj.feedingdate = this.$moment(tmpfeedingdate).fromNow()
              } else if (
                Math.ceil(
                  (nowDate.getTime() - tmpfeedingdate.getTime()) / 86400000
                ) >= 1
              ) {
                obj.feedingdate = Math.ceil(
                  (nowDate.getTime() - tmpfeedingdate.getTime()) / 86400000
                )
              }
            }
          }
          if (item.name === 'feedingquantity') {
            if (item.value !== 0 && item.value !== '') {
              obj.feedingquantity = item.value
            } else {
              obj.feedingquantity = this.$t('common.unsetting')
            }
          }
        })

        return obj
      } catch (error) {
        console.log(error)
        return {}
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      const vuethis = this
      const queryParams = {
        casepn: vuethis.casepn,
        regionpn: vuethis.regionkey,
        callback_params: `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionkey}_${vuethis.random_number}`,
      }
      this.$socket.emit('casecloud_getcasesregion_config', queryParams)
      this.getData()

      clearInterval(this.interval_devicecloud_getdevice)
      this.interval_devicecloud_getdevice = setInterval(() => {
        this.getData()
      }, 500)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      document.removeEventListener('visibilitychange', undefined)
      clearInterval(this.interval_devicecloud_getdevice)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    page_initialize() {
      try {
        this.casepn = this.$route.params.casepn
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply_devicecloud_getdevicehistorydata',
            this.socketevent_reply_devicecloud_getdevicehistorydata
          )
          this.sockets.subscribe(
            'reply_devicecloud_getdevicelatestrawdata',
            this.socketevent_reply_devicecloud_getdevicelatestrawdata
          )
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_config',
            this.socketevent_casecloud_getcaseregion_config
          )
        } else {
          this.sockets.unsubscribe('reply_devicecloud_getdevicehistorydata')
          this.sockets.unsubscribe('reply_devicecloud_getdevicelatestrawdata')
          this.sockets.unsubscribe('reply casecloud_getcasesregion_config')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_devicecloud_getdevicehistorydata(params) {
      try {
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'casestatus') {
              return
            }
          }
        }

        if (params === undefined || params.type === 'error') {
          return
        }
        if (params.msg === undefined) {
          return
        }
        const tmpdeviceHistoryData = { ...this.deviceHistoryData }
        if (params.devicetype === undefined || params.devicetype !== 'scale') {
          Object.keys(params.msg).forEach(smcpn => {
            Object.keys(params.msg[smcpn]).forEach(devicepn => {
              if (tmpdeviceHistoryData[smcpn] === undefined) {
                tmpdeviceHistoryData[smcpn] = {}
              }
              if (tmpdeviceHistoryData[smcpn][devicepn] === undefined) {
                tmpdeviceHistoryData[smcpn][devicepn] = {}
              }
              if (tmpdeviceHistoryData[smcpn][devicepn].history === undefined) {
                tmpdeviceHistoryData[smcpn][devicepn].history = {}
              }
              if (
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata ===
                undefined
              ) {
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata = {}
              }
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata =
                Object.assign(
                  [],
                  tmpdeviceHistoryData[smcpn][devicepn].history.detaildata,
                  params.msg[smcpn][devicepn]
                )
              Object.keys(
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata
              ).forEach(dateindex => {
                const tmpdevicedata =
                  tmpdeviceHistoryData[smcpn][devicepn].history.detaildata[
                    dateindex
                  ]
                let targetvalue = null
                let targetunit = ''
                const targetdatetime = tmpdevicedata.datetime
                const targetname = tmpdevicedata.name
                if (
                  tmpdevicedata.data !== undefined &&
                  tmpdevicedata.replace !== undefined &&
                  tmpdevicedata.replace !== null &&
                  tmpdevicedata.replace !== ''
                ) {
                  targetvalue = tmpdevicedata.data[tmpdevicedata.replace]
                  targetunit =
                    tmpdevicedata.replace.split('#').length > 1
                      ? tmpdevicedata.replace.split('#')[1]
                      : ''
                } else {
                  if (tmpdevicedata.devicetype === 'controller') {
                    if (this.deviceData !== undefined) {
                      if (this.deviceData[smcpn] !== undefined) {
                        if (
                          this.deviceData[smcpn].devices[devicepn] !== undefined
                        ) {
                          if (
                            this.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata !== undefined
                          ) {
                            if (
                              this.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.equipmenttype === 'heater'
                            ) {
                              targetvalue = Number(
                                Number.parseFloat(
                                  tmpdevicedata.data.PV / 10
                                ).toFixed(2)
                              )
                              targetunit = ''
                            }
                            if (
                              this.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.equipmenttype === 'inlet'
                            ) {
                              targetvalue = Number(
                                Number.parseFloat(
                                  tmpdevicedata.data.PV
                                ).toFixed(2)
                              )
                              targetunit = ''
                            }
                            if (
                              this.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.equipmenttype === 'tunnel'
                            ) {
                              targetvalue = Number(
                                Number.parseFloat(
                                  tmpdevicedata.data.PV
                                ).toFixed(2)
                              )
                              targetunit = ''
                            }
                          }
                        }
                      }
                    }
                  }
                  if (this.deviceData[smcpn] !== undefined) {
                    if (
                      this.deviceData[smcpn].devices[devicepn] !== undefined &&
                      this.deviceData[smcpn].devices[devicepn]
                        .latest_rawdata !== undefined
                    ) {
                      if (
                        this.deviceData[smcpn].devices[devicepn].latest_rawdata
                          .equipmenttype === 'waterflowmeter'
                      ) {
                        targetvalue = Number(
                          Number.parseFloat(
                            tmpdevicedata.data['TodayWaterMeter#L']
                          ).toFixed(2)
                        )
                        targetunit = ''
                      }
                    }
                  }
                }
                if (
                  tmpdeviceHistoryData[smcpn][devicepn].history.summarydata ===
                  undefined
                ) {
                  tmpdeviceHistoryData[smcpn][devicepn].history.summarydata = {}
                }
                if (
                  tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                    targetdatetime
                  ] === undefined
                ) {
                  tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                    targetdatetime
                  ] = {}
                }
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                  targetdatetime
                ] = {
                  datetime: targetdatetime,
                  value: targetvalue,
                  unit: targetunit,
                  name: targetname,
                }
              })
            })
            if (this.autogethistory === undefined) {
              this.autogethistory = {}
            }
            if (this.autogethistory[smcpn] === undefined) {
              this.autogethistory[smcpn] = {}
            }
            this.autogethistory[smcpn] = new Date().getTime()
            this.autogethistory = { ...this.autogethistory }
          })
          this.deviceHistoryData = { ...tmpdeviceHistoryData }
          this.getChartOption()
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_devicecloud_getdevicelatestrawdata(params) {
      try {
        // console.log('socketevent_reply_devicecloud_getdevicelatestrawdata')
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'casestatus') {
              return
            }
          }
        }

        if (params === undefined || params.type === 'error') {
          this.isloading = false
          return
        }
        if (params.msg === undefined) {
          this.isloading = false
          return
        }
        const tmpdeviceData = { ...vuethis.deviceData }
        if (params.devicetype === undefined || params.devicetype !== 'scale') {
          Object.keys(params.msg).forEach(smcpn => {
            Object.keys(params.msg[smcpn]).forEach(devicepn => {
              try {
                if (tmpdeviceData[smcpn] === undefined) {
                  tmpdeviceData[smcpn] = {}
                }
                if (tmpdeviceData[smcpn].devices === undefined) {
                  tmpdeviceData[smcpn].devices = {}
                }
                if (tmpdeviceData[smcpn].devices[devicepn] === undefined) {
                  tmpdeviceData[smcpn].devices[devicepn] = {}
                }
                tmpdeviceData[smcpn].devices[devicepn].latest_rawdata =
                  params.msg[smcpn][devicepn]
              } catch (error) {
                console.log(error)
              }
            })
          })
        } else {
          Object.keys(params.msg).forEach(smcpn => {
            try {
              if (tmpdeviceData[smcpn] === undefined) {
                tmpdeviceData[smcpn] = {}
              }
              tmpdeviceData[smcpn].latest_rawdata = params.msg[smcpn]
              if (
                tmpdeviceData[smcpn].latest_rawdata.statistics !== undefined
              ) {
                if (
                  tmpdeviceData[smcpn].latest_rawdata.statistics[0] !==
                  undefined
                ) {
                  tmpdeviceData[smcpn].average =
                    tmpdeviceData[smcpn].latest_rawdata.statistics[0].average
                  tmpdeviceData[smcpn].value = tmpdeviceData[smcpn].average
                  tmpdeviceData[smcpn].gain =
                    tmpdeviceData[smcpn].latest_rawdata.statistics[0].gain
                  tmpdeviceData[smcpn].cv =
                    tmpdeviceData[smcpn].latest_rawdata.statistics[0].cv
                  tmpdeviceData[smcpn].count =
                    tmpdeviceData[smcpn].latest_rawdata.statistics[0].count
                  tmpdeviceData[smcpn].target =
                    tmpdeviceData[smcpn].latest_rawdata.statistics[0].target
                }
              }
            } catch (error) {
              console.log(error)
            }
          })
        }

        vuethis.deviceData = { ...tmpdeviceData }
        vuethis.$forceUpdate()
        vuethis.isloading = false
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_config(params) {
      try {
        const vuethis = this
        let obj = [
          { name: 'feedingdate', value: '' },
          { name: 'feedingquantity', value: '' },
          { name: 'house_length', value: '' },
          { name: 'house_height', value: '' },
          { name: 'house_width', value: '' },
          { name: 'house_sectionalarea', value: '' },
        ]
        // operatingtime feedingdate飼養日期
        if (params === undefined || params.type === 'error') {
          return
        }
        if (
          params.callback_params !==
          `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionkey}_${vuethis.random_number}`
        ) {
          return
        }
        if (params.msg === undefined) {
          return
        }
        if (params.msg.data !== undefined) {
          if (Object.keys(params.msg.data).length > 0) {
            if (params.msg.data.parameter_data.length !== 0) {
              obj = params.msg.data.parameter_data
            }
          }
        }
        vuethis.regioninfo = []
        vuethis.regioninfo = obj
      } catch (error) {
        console.log(error)
      }
    },
    getRegionDeviceTypeArray_Sensor(params, sortArray) {
      try {
        let tmpArray = Object.keys(params)
        tmpArray = tmpArray.filter(a => sortArray.indexOf(a) > -1)
        tmpArray.sort((a, b) => {
          try {
            if (sortArray.indexOf(a) > sortArray.indexOf(b)) {
              return 1
            }
            if (sortArray.indexOf(a) < sortArray.indexOf(b)) {
              return -1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return []
      }
    },
    getRegionDeviceTypeArray_IPCam(params) {
      try {
        const sortArray = ['ipcam_pigweigh']
        let tmpArray = Object.keys(params)
        tmpArray = tmpArray.filter(a => sortArray.indexOf(a) > -1)
        tmpArray.sort((a, b) => {
          try {
            if (sortArray.indexOf(a) > sortArray.indexOf(b)) {
              return 1
            }
            if (sortArray.indexOf(a) < sortArray.indexOf(b)) {
              return -1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return []
      }
    },
    regiondevices(tmpdevicetype) {
      try {
        const tmpArray = []
        if (Object.keys(this.tmpregiondeviceslist) === 0) {
          return []
        }
        if (Object.keys(this.tmpregiondeviceslist[tmpdevicetype]) === 0) {
          return []
        }
        if (
          Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices) === 0
        ) {
          return []
        }
        Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices).forEach(
          item => {
            const obj = this.tmpregiondeviceslist[tmpdevicetype].devices[item]
            obj.regionitemkey = item
            tmpArray.push(obj)
          }
        )
        tmpArray.sort((a, b) => {
          try {
            if (a.index !== undefined) {
              if (a.index > b.index) {
                return 1
              }
            }
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return []
      }
    },
    async getData() {
      try {
        const devicetypeArray = this.getRegionDeviceTypeArray_Sensor(
          this.tmpregiondeviceslist,
          ['heater', 'inlet', 'tunnel', 'meter_water', 'scale']
        )
        const nowDate = new Date().getTime()
        const datatime = new Date(nowDate)
        const data_hour = datatime.getHours()
        const data_min = datatime.getMinutes()
        const data_sec = datatime.getSeconds()
        if (data_hour === 0 && data_min === 0 && data_sec === 0) {
          this.residenceTime.lastdatatime = 0
          this.residenceTime.historydatatime = 0
        }
        if (
          nowDate - this.residenceTime.lastdatatime >
          this.getdatafrequencyTime.lastdatatime
        ) {
          devicetypeArray.forEach(tmpdevicetype => {
            if (
              Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices)
                .length > 0
            ) {
              this.getLastdata(tmpdevicetype)
            }
          })
          this.residenceTime.lastdatatime = nowDate
        }
        await Tientech_delay(200)
        if (
          nowDate - this.residenceTime.historydatatime >
          this.getdatafrequencyTime.historydatatime
        ) {
          devicetypeArray.forEach(async tmpdevicetype => {
            if (
              Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices)
                .length > 0
            ) {
              if (tmpdevicetype !== 'scale') {
                this.getHistoryData(this.regiondevices(tmpdevicetype), false)
              }
              if (tmpdevicetype === 'scale') {
                this.getscaleHistoryData(
                  this.regiondevices(tmpdevicetype),
                  true
                )
              }
              await Tientech_delay(200)
            }
          })
          this.residenceTime.historydatatime = nowDate
        }
      } catch (error) {
        console.log(error)
      }
    },
    getLastdata(tmpdevicetype) {
      try {
        if (this.regiondevices(tmpdevicetype).length > 0) {
          if (tmpdevicetype !== 'scale') {
            this.getHistoryData(this.regiondevices(tmpdevicetype), true)
          }
          if (tmpdevicetype === 'scale') {
            this.getscaleHistoryData(this.regiondevices(tmpdevicetype), true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    getscaleHistoryData(regiondevice, getlatestrawdata) {
      try {
        // console.log(regiondevice)
        const vuethis = this
        if (regiondevice === undefined) {
          return
        }
        const obj = {}
        const smcdevicelist = []
        regiondevice.forEach(deviceitem => {
          const pn = deviceitem.pn
          if (pn === undefined) {
            return
          }

          if (smcdevicelist.indexOf(pn) === -1) {
            smcdevicelist.push(pn)
          }
          if (obj[pn] === undefined) {
            obj[pn] = {}
          }

          if (vuethis.deviceData === undefined) {
            vuethis.deviceData = {}
          }
          if (vuethis.deviceData[pn] === undefined) {
            vuethis.deviceData[pn] = {}
            vuethis.deviceData[pn].devices = {}
          }

          if (vuethis.deviceHistoryData === undefined) {
            vuethis.deviceHistoryData = {}
          }
          if (vuethis.deviceHistoryData[pn] === undefined) {
            vuethis.deviceHistoryData[pn] = {}
          }
          if (vuethis.autogethistory === undefined) {
            vuethis.autogethistory = {}
          }
          if (vuethis.autogethistory[pn] === undefined) {
            vuethis.autogethistory[pn] = 0
          }
        })
        const datatime = new Date()
        const data_year = datatime.getFullYear()
        const data_month = `0${datatime.getMonth() + 1}`.substr(-2)
        const data_date = `0${datatime.getDate()}`.substr(-2)
        const data_hour = `0${datatime.getHours()}`.substr(-2)
        const data_min = `0${datatime.getMinutes()}`.substr(-2)
        const date_daily = [data_year, data_month, data_date].join('/')
        const tmpdatetime = new Date(`${date_daily} 00:00:00`).getTime()
        this.isloading = true
        if (getlatestrawdata === false) {
          Object.keys(obj).forEach(pn => {
            if (
              vuethis.autogethistory[pn] !== undefined &&
              vuethis.autogethistory[pn] !== 0
            ) {
              const nowDate = new Date().getTime()
              if (nowDate - vuethis.autogethistory[pn] >= 10 * 60 * 1000) {
                vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                  datetime: tmpdatetime,
                  devicetype: 'scale',
                  data: obj,
                  devicelist: smcdevicelist,
                  callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
                })
              }
            } else {
              // console.log(smcdevicelist)
              vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                data: obj,
                devicetype: 'scale',
                devicelist: smcdevicelist,
                callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
              })
            }
          })
        } else {
          vuethis.$socket.emit('devicecloud_getdevicelatestrawdata', {
            data: obj,
            devicetype: 'scale',
            devicelist: smcdevicelist,
            callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    getHistoryData(regiondevice, getlatestrawdata) {
      try {
        const vuethis = this
        if (regiondevice === undefined) {
          return
        }
        const obj = {}
        const smcdevicelist = []
        regiondevice.forEach(deviceitem => {
          const smcpn = deviceitem.smcpn
          const devicepn = deviceitem.devicepn
          if (smcdevicelist.indexOf(smcpn) === -1) {
            smcdevicelist.push(smcpn)
          }
          if (obj[smcpn] === undefined) {
            obj[smcpn] = {}
          }
          if (obj[smcpn][devicepn] === undefined) {
            obj[smcpn][devicepn] = { devicepn }
          }
          if (vuethis.deviceData === undefined) {
            vuethis.deviceData = {}
          }
          if (vuethis.deviceData[smcpn] === undefined) {
            vuethis.deviceData[smcpn] = {}
            vuethis.deviceData[smcpn].devices = {}
          }
          if (vuethis.deviceData[smcpn].devices[devicepn] === undefined) {
            vuethis.deviceData[smcpn].devices[devicepn] = {}
          }
          if (vuethis.deviceHistoryData === undefined) {
            vuethis.deviceHistoryData = {}
          }
          if (vuethis.deviceHistoryData[smcpn] === undefined) {
            vuethis.deviceHistoryData[smcpn] = {}
          }
          if (vuethis.autogethistory === undefined) {
            vuethis.autogethistory = {}
          }
          if (vuethis.autogethistory[smcpn] === undefined) {
            vuethis.autogethistory[smcpn] = 0
          }
        })
        const datatime = new Date()
        const data_year = datatime.getFullYear()
        const data_month = `0${datatime.getMonth() + 1}`.substr(-2)
        const data_date = `0${datatime.getDate()}`.substr(-2)
        const data_hour = `0${datatime.getHours()}`.substr(-2)
        const data_min = `0${datatime.getMinutes()}`.substr(-2)
        const date_daily = [data_year, data_month, data_date].join('/')
        const tmpdatetime = new Date(`${date_daily} 00:00:00`).getTime()
        this.isloading = true
        if (getlatestrawdata === false) {
          Object.keys(obj).forEach(smcpn => {
            if (
              vuethis.autogethistory[smcpn] !== undefined &&
              vuethis.autogethistory[smcpn] !== 0
            ) {
              const nowDate = new Date().getTime()
              if (nowDate - vuethis.autogethistory[smcpn] >= 10 * 60 * 1000) {
                vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                  datetime: tmpdatetime,
                  data: obj,
                  devicelist: smcdevicelist,
                  callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
                })
              }
            } else {
              // console.log(smcdevicelist)
              vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                data: obj,
                devicelist: smcdevicelist,
                callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
              })
            }
          })
        } else {
          vuethis.$socket.emit('devicecloud_getdevicelatestrawdata', {
            data: obj,
            devicelist: smcdevicelist,
            callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    getChartOption() {
      const vuethis = this
      try {
        const nowDate = new Date().getTime()
        const datatime = new Date()
        const data_year = datatime.getFullYear()
        const data_month = `0${datatime.getMonth() + 1}`.substr(-2)
        const data_date = `0${datatime.getDate()}`.substr(-2)
        const data_hour = `0${datatime.getHours()}`.substr(-2)
        const data_min = `0${datatime.getMinutes()}`.substr(-2)
        const date_daily = [data_year, data_month, data_date].join('/')
        const max_selectdate = new Date(`${date_daily} 23:59:59`).getTime()
        const min_selectdate = new Date(`${date_daily} 00:00:00`).getTime()
        const devicetypeArray = this.getRegionDeviceTypeArray_Sensor(
          this.tmpregiondeviceslist,
          ['heater', 'inlet', 'tunnel', 'meter_water']
        )
        devicetypeArray.forEach(tmpdevicetype => {
          if (vuethis.chartoptions[tmpdevicetype] === undefined) {
            vuethis.chartoptions[tmpdevicetype] = { ...this.chartoption }
            vuethis.chartoptions[tmpdevicetype].series = []
          }

          vuethis.chartoptions[tmpdevicetype] = { ...this.chartoption }
          vuethis.chartoptions[tmpdevicetype].series = []
          let maxvalue = null
          let minvalue = null
          if (vuethis.regiondevicesHistoryData[tmpdevicetype] === undefined) {
            vuethis.regiondevicesHistoryData[tmpdevicetype] = {
              maxvalue: null,
              minvalue: null,
            }
          }
          vuethis.regiondevices(tmpdevicetype).forEach(deviceitem => {
            const smcpn = deviceitem.smcpn
            const devicepn = deviceitem.devicepn
            if (vuethis.deviceHistoryData === undefined) {
              return
            }
            if (vuethis.deviceHistoryData[smcpn] === undefined) {
              return
            }
            if (vuethis.deviceHistoryData[smcpn][devicepn] === undefined) {
              return
            }
            if (
              vuethis.deviceHistoryData[smcpn][devicepn].history === undefined
            ) {
              return
            }

            const region_device_name = deviceitem.name
            const xAxis = {
              data: [],
              showSymbol: false,
              hoverAnimation: false,
              type: 'line',
              smooth: true,
              name: region_device_name,
              connectNulls: false,
            }
            const dayarray = []
            // 找出符合時段
            if (
              vuethis.deviceHistoryData[smcpn][devicepn].history.summarydata !==
              undefined
            ) {
              Object.keys(
                vuethis.deviceHistoryData[smcpn][devicepn].history.summarydata
              ).forEach(daykey => {
                if (
                  parseInt(daykey, 10) <= max_selectdate &&
                  parseInt(daykey, 10) >= min_selectdate
                ) {
                  dayarray.push(daykey)
                }
              })
            }
            dayarray.sort((a, b) => parseInt(a, 10) - parseInt(b, 10))

            let lasttime = 0
            const shifttime = 60 * 1000 * 10
            // 補中間資料
            dayarray.forEach(dataindex => {
              if (lasttime === 0) {
                lasttime = parseInt(dataindex, 10)
              } else {
                if (parseInt(dataindex, 10) - lasttime > shifttime) {
                  for (
                    let j = 1;
                    shifttime * j + lasttime < parseInt(dataindex, 10);
                    j += 1
                  ) {
                    xAxis.data.push({
                      name: new Date(parseInt(lasttime + j * shifttime, 10)),
                      value: [parseInt(lasttime + j * shifttime, 10), null],
                      devicetype: tmpdevicetype,
                      unit: '',
                    })
                  }
                }
                lasttime = parseInt(dataindex, 10)
              }
              const tmpdata =
                vuethis.deviceHistoryData[smcpn][devicepn].history.summarydata[
                  dataindex
                ]
              if (tmpdata.value !== undefined && tmpdata.value !== null) {
                if (maxvalue === null) {
                  maxvalue = tmpdata.value
                  minvalue = tmpdata.value
                }
                if (tmpdata.value > maxvalue) {
                  maxvalue = tmpdata.value
                }
                if (tmpdata.value < minvalue) {
                  minvalue = tmpdata.value
                }
                xAxis.data.push({
                  name: new Date(parseInt(tmpdata.datetime, 10)),
                  value: [parseInt(tmpdata.datetime, 10), tmpdata.value],
                  devicetype: tmpdevicetype,
                  unit: tmpdata.unit,
                })
              }
            })

            // 後面才段補尾段資料
            if (lasttime !== 0) {
              if (nowDate < max_selectdate) {
                if (max_selectdate - lasttime > shifttime) {
                  for (
                    let j = 1;
                    lasttime + j * shifttime <= max_selectdate;
                    j += 1
                  ) {
                    if (nowDate <= lasttime + j * shifttime) {
                      break
                    }
                    // console.log(max_selectdate - parseInt(lasttime + j * shifttime, 10))
                    xAxis.data.push({
                      name: new Date(
                        max_selectdate - parseInt(lasttime + j * shifttime, 10)
                      ),
                      value: [parseInt(lasttime + j * shifttime, 10), null],
                      devicetype: tmpdevicetype,
                      unit: '',
                    })
                    if (j >= 1440) {
                      break
                    }
                  }
                }
              }
            } else {
              // 全部都沒資料
              for (
                let j = 1;
                min_selectdate + j * shifttime <= max_selectdate;
                j += 1
              ) {
                if (nowDate <= min_selectdate + j * shifttime) {
                  break
                }
                xAxis.data.push({
                  name: new Date(min_selectdate + parseInt(j * shifttime, 10)),
                  value: [
                    new Date(min_selectdate + parseInt(j * shifttime, 10)),
                    null,
                  ],
                  devicetype: tmpdevicetype,
                  unit: '',
                })
                if (j >= 1440) {
                  break
                }
              }
            }
            vuethis.chartoptions[tmpdevicetype].series.push(xAxis)
          })
          vuethis.regiondevicesHistoryData[tmpdevicetype].maxvalue =
            maxvalue !== null ? Number(Number(maxvalue).toFixed(2)) : '-'
          vuethis.regiondevicesHistoryData[tmpdevicetype].minvalue =
            minvalue !== null ? Number(Number(minvalue).toFixed(2)) : '-'
        })
      } catch (error) {
        console.log(error)
      }
    },
    getIcon(type) {
      try {
        let icon = ''
        switch (type) {
          case 'heater':
            icon = 'fa-solid fa-fire fa-lg'
            break
          case 'inlet':
            icon = 'fa-solid fa-window-maximize fa-lg'
            break
          case 'tunnel':
            icon = 'fa-solid fa-window-maximize fa-lg'
            break
          case 'meter_water':
            icon = 'fa-solid fa-water fa-lg'
            break
          case 'scale':
            icon = 'fa-solid fa-weight-scale fa-lg'
            break
          default:
            icon = 'fa-solid fa-question fa-lg'
            break
        }
        return icon
      } catch (error) {
        console.log(error)
        return 'fa-solid fa-question fa-lg'
      }
    },
    getVariant(type, val) {
      try {
        let variant = ''
        switch (type) {
          case 'heater':
            if (val > 31) {
              variant = 'light-danger'
            } else {
              variant = 'light-primary'
            }
            break
          case 'inlet':
            if (val > 93) {
              variant = 'light-danger'
            } else {
              variant = 'light-primary'
            }
            break
          case 'tunnel':
            if (val > 1500) {
              variant = 'light-danger'
            } else {
              variant = 'light-primary'
            }
            break
          default:
            variant = 'light-primary'
            break
        }
        return variant
      } catch (error) {
        console.log(error)
        return 'light-primary'
      }
    },
    getTimeFormat(time) {
      try {
        if (time === undefined || time === null || time === 0) {
          return 'loading..'
        }

        // return `${data_year}/${data_month}/${data_date} <br> ${data_hour}:${data_min}`
        const nowDatetime = new Date().getTime()
        if (nowDatetime - time > 60 * 1000 * 60) {
          return this.$moment(time).format('MMMM Do YYYY, h:mm:ss a')
        }
        return this.$moment(time).fromNow()
      } catch (error) {
        console.log(error)
        return ''
      }
    },
    getRegionDeviceCardCSS(type, obj) {
      let css =
        'min-width: 180px; border-left: 5px solid rgb(116 132 116) !important;'
      if (obj.datetime === 0) return css
      const val = obj.value
      const isoffline = obj.isoffline
      switch (type) {
        case 'heater':
          if (isoffline === false) {
            if (val > 31) {
              css =
                'min-width: 170px; border-left: 7px solid rgb(230 112 25) !important;'
            } else {
              css =
                'min-width: 170px; border-left: 7px solid rgb(75 179 84) !important;'
            }
          } else {
            css =
              'min-width: 170px; border-left: 7px solid rgb(238 112 112) !important;'
          }
          break
        case 'inlet':
          if (isoffline === false) {
            if (val > 93) {
              css =
                'min-width: 170px; border-left: 7px solid rgb(230 112 25) !important;'
            } else {
              css =
                'min-width: 170px; border-left: 7px solid rgb(75 179 84) !important;'
            }
          } else {
            css =
              'min-width: 170px; border-left: 7px solid rgb(238 112 112) !important;'
          }
          break
        case 'tunnel':
          if (isoffline === false) {
            if (val > 1500) {
              css =
                'min-width: 170px; border-left: 7px solid rgb(230 112 25) !important;'
            } else {
              css =
                'min-width: 170px; border-left: 7px solid rgb(75 179 84) !important;'
            }
          } else {
            css =
              'min-width: 170px; border-left: 7px solid rgb(238 112 112) !important;'
          }
          break
        default:
          css =
            'min-width: 180px; border-left: 8px solid rgb(116 132 116) !important;'
          break
      }
      return css
    },
    getRegionTypeCol() {
      let colnumb = 'col-12'
      try {
        if (this.regionshowmode === 'simple') {
          colnumb = 'col-12 col-xs-12 col-md-6 '
        } else {
          colnumb = 'col-12'
        }
        return colnumb
      } catch (error) {
        console.log(error)
        return colnumb
      }
    },
    getRegionDeviceCol() {
      let colnumb = 'my-1 col-auto'
      try {
        if (this.regionshowmode === 'simple') {
          colnumb = 'my-1 col-md-6 col-lg-4'
        } else {
          colnumb = 'my-1 col-auto'
        }
        return colnumb
      } catch (error) {
        console.log(error)
        return colnumb
      }
    },
    getDatetimeisOffline(datatime) {
      try {
        if (datatime === undefined || datatime === null) {
          return false
        }
        if (new Date().getTime() - datatime < this.overtime) {
          return false
        }
        return true
      } catch (error) {
        console.log(error)
        return true
      }
    },
  },
}
</script>
<style lang="scss">
// .echarts {
//   width: 100%;
//   height: 180px;
// }
</style>
